import React from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Weather from '../Weather';
import Form from '../Form/';
import Time from '../Time';
import logo from '../../images/logo.png';
import './style.scss';

function Header( props ) {
	const location = useLocation();
	return (
		<header>
			<div className="container">
				<img alt="OnlineNewsApp" className={'logo'} src={logo} />
			</div>
		</header>
	);
}
const mapStateToProps = ( state ) => {
	return {
		showBg: state.settings.options.background.visible,
		showWeather: state.settings.options.weather.visible,
		showTime: state.settings.options.time.visible,
		preferences: state.preferences.init
	};
};

export default connect( mapStateToProps, {} )( Header );
