import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import TodosReducer from './TodosReducer';
import SettingsReducer from './SettingsReducer';
import PreferencesReducer from './PreferencesReducer';
import WeatherReducer from './WeatherReducer';
import ToolbarReducer from './ToolbarReducer';
import TrackingReducer from './TrackingReducer';
import AppReducer from './AppReducer';
import TopBarReducer from './TopBarReducer';

import visibilityFilter from './visibilityFilter';

const appPersistConfig = {
	key: 'app',
	storage: storage,
	blacklist: ['selected_category', 'selected_provider', 'searching', 'results', 'hasMore', 'loadingMore', 'page'],
};

const todoApp = combineReducers({
	todos: TodosReducer,
	settings: SettingsReducer,
	preferences: PreferencesReducer,
	weather: WeatherReducer,
	toolbar: ToolbarReducer,
	topBar: TopBarReducer,
	tracking: TrackingReducer,
	app: persistReducer(appPersistConfig, AppReducer),
	visibilityFilter,
});

export default todoApp;
