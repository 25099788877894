import React, { useEffect, useRef, useState } from 'react';
import { Icon } from 'react-icons-kit';
import { iosSearchStrong } from 'react-icons-kit/ionicons/iosSearchStrong';
import { close } from 'react-icons-kit/ionicons/close';

import { getAutocompleteSuggestions } from '../../actions/AppActions';

import './style.scss';

function NewsForm( { defaultValue, ...props } ) {
	// Ref of input to focus on component mount
	const inputEl = useRef( null );
	// Ref of form to submit on suggestion click
	const formEl = useRef( null );
	// Suggestions array
	const [suggestions, setSuggestions] = useState( [] );
	// Input value
	const [searchTerm, setSearchTerm] = useState( '' );
	// Display/Hide suggestions box
	const [displaySuggestions, setDisplaySuggestions] = useState( false );
	// Highlight suggestion item for keyword up down navigation
	const [activeSuggestion, setActiveSuggestion] = useState( -1 );
	// Should we hide the suggestion box on input blur, was the blur generated from a suggestion click
	const [choosingSuggestion, setChoosingSuggestion] = useState( false );

	// Component mount
	useEffect( () => {
		setDisplaySuggestions( true );
	}, [] );

	useEffect( () => {
		setSearchTerm( defaultValue );
	}, [props.reset, defaultValue] );

	// Handle input change, query for suggestion and show suggestions
	var handleOnChange = function( e ) {
		var newSearchValue = e.target.value;
		setSearchTerm( newSearchValue );
		if( e.target.value === '' ) {
			setSuggestions( [] );
		} else {
			if( newSearchValue.length > 3 ) {
				getAutocompleteSuggestions( newSearchValue, function( results ) {
					if( results.length === 0 ) {
						setSuggestions( [] );
					} else {
						var _suggestions = results.map( ( sugg ) => {
							return {
								key: sugg.replace(
									new RegExp( newSearchValue, 'gi' ),
									'<strong>$&</strong>'
								),
								plain: sugg,
							};
						} );
						setSuggestions( _suggestions );
					}
				} );
			}
		}
	};

	// Display suggestion if any, on input focus
	var handleOnFocus = function() {
		setDisplaySuggestions( true );
	};

	// Hide suggestions box on input blur
	var handleOnBlur = function( e ) {
		// do nothing if blur comes from a suggestion click
		if( choosingSuggestion ) return;

		if( suggestions.length > 0 ) setDisplaySuggestions( false );
	};

	// Let state know the blur will come from a suggestion click
	var handleMouseDown = function() {
		setChoosingSuggestion( true );
	};

	// Handle Suggestion click, update input value and submit form
	var handleSuggestionClick = function( e ) {
		var newSearchTerm = e.target.innerText;
		setSearchTerm( newSearchTerm );
		setTimeout( () => {
			setDisplaySuggestions( false );
			setChoosingSuggestion( false );
			setSuggestions( [] );
			props.onSubmit( newSearchTerm );
		}, 100 );
	};

	// Keyboard Arrow Up/Down navigation
	var moveThroughSuggestions = function( next ) {
		var newActiveSuggestion = activeSuggestion + next;
		if( newActiveSuggestion < 0 ) {
			newActiveSuggestion = suggestions.length - 1;
		} else if( newActiveSuggestion === suggestions.length ) {
			newActiveSuggestion = 0;
		}
		setSearchTerm( suggestions[newActiveSuggestion].plain );
		setActiveSuggestion( newActiveSuggestion );
	};

	// Handle keyboard up/down event
	var handleKeyUp = function( e ) {
		if( e.keyCode === 38 ) {
			// arrowUp
			e.preventDefault();
			moveThroughSuggestions( -1 );
		} else if( e.keyCode === 40 ) {
			// arrowDown
			e.preventDefault();
			moveThroughSuggestions( 1 );
		}
	};

	// X inside the input
	var handleResetClick = function() {
		setSearchTerm( '' );
		setSuggestions( [] );
		inputEl.current.focus();
	};

	// On Form Submit / On Search
	var handleFormSubmit = function( e ) {
		e.preventDefault();
		if( !searchTerm ) {
			inputEl.current.focus();
			return;
		}
		setSuggestions( [] );
		setDisplaySuggestions( false );
		props.onSubmit( searchTerm );
	};

	return (
		<form className="news-search" action="#" ref={formEl} onSubmit={handleFormSubmit}>
			<fieldset className="search">
				<input
					onKeyUp={handleKeyUp}
					onBlur={handleOnBlur}
					onFocus={handleOnFocus}
					value={searchTerm}
					onChange={handleOnChange}
					ref={inputEl}
					autoComplete="off"
					placeholder={props.placeholder}
					name="p"
					type="text"
				/>
				<button>
					<Icon size="30" icon={iosSearchStrong} />
				</button>
				{searchTerm && (
					<a className="delete" onClick={handleResetClick}>
						<Icon size="30" icon={close} />
					</a>
				)}
			</fieldset>
			{suggestions.length > 0 && displaySuggestions && (
				<ul className="autocomplete">
					{suggestions.map( ( s, i ) => {
						return (
							<li
								className={i === activeSuggestion ? 'hover' : ''}
								onMouseDown={handleMouseDown}
								onClick={handleSuggestionClick}
								key={i}
							>
								<Icon size="30" icon={iosSearchStrong} />
								<span dangerouslySetInnerHTML={{ __html: s.key }}></span>
							</li>
						);
					} )}
				</ul>
			)}
		</form>
	);
}

export default NewsForm;
