import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import Listings from '../../components/Listings';
import { searchCategory, selectCategory, selectProvider } from '../../actions/AppActions';
import { resetTopBarInteractions } from '../../actions/TopBarActions';

import './style.scss';

function ListingsPage( {
	resetTopBarInteractions,
	selected_category,
	selectCategory,
	searchCategory,
	selectProvider
} ) {
	let { id } = useParams();

	useEffect( () => {
		resetTopBarInteractions( 'user' );
	}, [] );

	useEffect( () => {
		selectCategory( id );
		searchCategory( id );
		selectProvider( null );
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id] );

	return <div className="toolbar">{selected_category && <Listings />}</div>;
}

const mapStateToProps = ( state ) => {
	return {
		selected_category: state.app.selected_category,
	};
};

export default connect( mapStateToProps, {
	selectCategory,
	searchCategory,
	resetTopBarInteractions,
	selectProvider
} )( ListingsPage );
