import React from 'react';

import ShortCut from './ShortCut';

function ShortCuts({ shortcuts, onRemoveShortCut }) {
	return shortcuts.map((item, index) => {
		return (
			<ShortCut
				onRemove={onRemoveShortCut}
				key={index}
				item={{
					id: item.id,
					text: item.text,
					url: item.url,
					favicon: item.favicon ? item.favicon : '',
					editable: item.editable
				}}
			/>
		);
	});
}

export default ShortCuts;
