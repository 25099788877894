import { TOGGLE_TOP_BAR_COLLAPSE, TOP_BAR_HIT, RESET_TOP_BAR_INTERACTIONS } from '../actions/types';

const INITIAL_STATE = {
	collapsed: false,
	collapsedBy: 'dash',
	hits: 0,
	lastInteraction: Date.now(),
};

const reducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case TOGGLE_TOP_BAR_COLLAPSE:
			return {
				...state,
				collapsed: !state.collapsed,
				collapsedBy: action.payload,
				hits: 0,
				lastInteraction: Date.now(), // Date.now() - 86400000 * 4
			};
		case TOP_BAR_HIT:
			return {
				...state,
				hits: state.hits + 1,
			};
		case RESET_TOP_BAR_INTERACTIONS:
			return {
				...state,
				hits: 0,
				collapsedBy: action.payload,
				lastInteraction: Date.now(), // Date.now() - 86400000 * 4
			};
		default:
			return state;
	}
};

export default reducer;
