import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Parser from 'html-react-parser';

import { setPreferencesInit } from '../../actions/PreferencesActions';

function Category({ setPreferencesInit, cat, onClick, selected_category }) {
	const [selected, setSelected] = useState(false);

	useEffect(() => {
		if (selected_category) {
			setSelected(cat.ID == selected_category);
		} else {
			setSelected(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selected_category]);

	let url = `/listings/${cat.ID}`;

	if (cat.ID == 'settings') {
		url = `/intro`;
	}
	return (
		<Link
			onClick={() => {
				setPreferencesInit(true);
			}}
			to={url}
			style={cat.PrimaryImage ? { backgroundImage: `url(categories/${cat.ID}.png)` } : null}
			className={`category-item ${selected ? 'selected' : ''} ${cat.icon ? 'icon' : ''}`}
		>
			{cat.icon && <img alt={Parser(cat.Category)} src={cat.icon.default} />}
			<span>{Parser(cat.Category)}</span>
		</Link>
	);
}

const mapStateToProps = (state) => {
	return {
		selected_category: state.app.selected_category,
	};
};

export default connect(mapStateToProps, {
	setPreferencesInit,
})(Category);
