import React from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import './style.scss';

import List from './List';

function Categories({ showBg, bg, darkMode, preferences }) {
	const location = useLocation();

	if (location.pathname === '/intro') {
		return null;
	}

	return (
		<section
			style={
				showBg
					? bg
						? { backgroundImage: `url('${bg}')` }
						: { backgroundImage: `url('../../images/bg.jpg)` }
					: null
			}
			className={`categories pt-3 pb-2${showBg ? ' bg' : ''}${darkMode ? ' dark' : ''}`}
		>
			<div className="container">
				<div className="row">
					<div className="col-12">
						<p
							style={showBg ? (bg && darkMode ? { color: '#fff' } : null) : null}
							className="text-center mt-0 mb-1 text-uppercase"
						>
							Keep Yourself up to date - Select a network
						</p>
						<List />
					</div>
				</div>
			</div>
		</section>
	);
}

const mapStateToProps = (state) => {
	return {
		showBg: state.settings.options.background.visible,
		preferences: state.preferences.init,
		bg: state.settings.backgroundURL,
		darkMode: state.settings.darkMode,
	};
};

export default connect(mapStateToProps, {})(Categories);
