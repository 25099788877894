import { SET_OPTION, SET_BACKGROUND } from '../actions/types';

const INITIAL_STATE = {
	options: {
		weather: {
			visible: true,
			name: 'Weather',
		},
		time: {
			visible: true,
			name: 'Time & Date',
		},
		quote: {
			visible: true,
			name: 'Quote',
		},
		todos: {
			visible: true,
			name: 'Todo List',
		},
		background: {
			visible: false,
			name: 'Background',
		},
	},
	backgroundURL: '',
	darkMode: false,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_OPTION:
			return {
				...state,
				options: {
					...state.options,
					[action.payload.key]: {
						...state.options[action.payload.key],
						visible: action.payload.value,
					},
				},
			};
		case SET_BACKGROUND:
			return {
				...state,
				backgroundURL: action.payload.bg,
				darkMode: action.payload.darkMode,
			};
		default:
			return state;
	}
};
