import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';

import { connect } from 'react-redux';

function Listing( { object, favoriteIDs } ) {
	const [isFavorite, setIsFavorite] = useState( false );

	useEffect( () => {
		if( favoriteIDs.length ) {
			if( favoriteIDs.indexOf( object.id ) !== -1 ) {
				setIsFavorite( true );
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [favoriteIDs] );

	return (
		<a target="_blank" href={object.url} className="listing">
			<div className="listing-img" style={{ backgroundImage: `url(${object.image})` }}>
				<div className="meta">
					<div className="img-wrap">
						<img src={`networks/${object.network}.png`} />
					</div>
					<span className="category">{object.category} - </span>
					<Moment fromNow>{object.posted_at}</Moment>
				</div>
			</div>
			{/* <a onClick={hanldeFavoriteClick} className={isFavorite ? 'favorite in' : 'favorite'}>
				<Icon icon={heart} />
			</a> */}
			<h4>
				<a target="_blank" href={object.url}>
					{object.title}
				</a>
			</h4>
			<p className="description">{object.description}</p>
		</a>
	);
}

var mapStateToProps = ( state ) => {
	return {
		favoriteIDs: state.app.favoriteIDs,
	};
};

export default connect( mapStateToProps, {} )( Listing );
