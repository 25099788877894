import React, { useState, useEffect } from 'react';
import moment from 'moment';

import './style.scss';

function Time(props) {
	const getCurrentDate = () => moment(new Date(), 'YYYY-MM-DD HH:mm:ss');

	const [date, setDate] = useState(getCurrentDate());

	useEffect(() => {
		let isSubscribed = true;
		let interval = setInterval(
			() => (isSubscribed ? setDate(getCurrentDate()) : null),
			1000
		);

		return () => {
			isSubscribed = false;
			clearInterval(interval);
		};
	}, []);

	return (
		<p className="date">
			{date.format('ddd')}, {date.format('MMM')} {date.format('DD')}
			<span>|</span>
			{date.format('hh')}:{date.format('mm')}{' '}
			{date.format('A')}
		</p>
	);
}

export default Time;
