import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { addShortCut, removeShortCut, getRemoteShortcuts } from '../../actions/ToolbarActions';

import { Icon } from 'react-icons-kit';
import { plus } from 'react-icons-kit/iconic/plus';

import ShortCut from './ShortCut';
import ShortCuts from './ShortCuts';

import './style.scss';

function ShortCutsList({
	remote_shortcuts,
	shortcuts,
	addShortCut,
	removeShortCut,
	getRemoteShortcuts,
}) {
	useEffect(() => {
		getRemoteShortcuts();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const isUrl = (s) => {
		var regexp = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/; //eslint-disable-line
		return regexp.test(s);
	};

	const buildURL = (url) => {
		if (url.includes('http://') && isUrl(url)) {
			return url;
		}
		if (url.includes('https://') && isUrl(url)) {
			return url;
		}
		url = `https://${url}`;
		if (isUrl(url)) return url;

		return false;
	};

	return (
		<div className="shortcuts">
			{remote_shortcuts && (
				<ShortCuts
					shortcuts={remote_shortcuts}
					onRemoveShortCut={(key) => {
						removeShortCut(key);
					}}
				/>
			)}
			<ShortCuts
				shortcuts={shortcuts}
				onRemoveShortCut={(key) => {
					removeShortCut(key);
				}}
			/>
		</div>
	);
}
const mapStateToProps = (state) => {
	return {
		shortcuts: Object.keys(state.toolbar.shortcuts).map((k) => {
			return { ...state.toolbar.shortcuts[k], id: k };
		}),
		remote_shortcuts: !state.toolbar.remote_shortcuts
			? null
			: Object.keys(state.toolbar.remote_shortcuts).map((k) => {
					return { ...state.toolbar.remote_shortcuts[k], id: k };
			  }),
	};
};

export default connect(mapStateToProps, {
	addShortCut,
	removeShortCut,
	getRemoteShortcuts,
})(ShortCutsList);
