import {
	ADD_TODO,
	REMOVE_TODO,
	MODIFY_TODO_STATE,
	MODIFY_TODO_TEXT,
	MODIFY_NOTE
} from '../actions/types';

const INITIAL_STATE = {
	todos: {
		
	},
	note: ''
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ADD_TODO:
			return {
				...state,
				todos: {
					...state.todos,
					[`todo${Math.floor(Math.random() * 10000 + 1)}`]: {
						text: '',
						open: true
					}
				}
			};
		case REMOVE_TODO:
			let todos = { ...state.todos };
			delete todos[action.payload];
			return { ...state, todos };
		case MODIFY_TODO_STATE:
			return {
				...state,
				todos: {
					...state.todos,
					[action.payload]: {
						...state.todos[action.payload],
						open: !state.todos[action.payload].open
					}
				}
			};
		case MODIFY_TODO_TEXT:
			return {
				...state,
				todos: {
					...state.todos,
					[action.payload.id]: {
						...state.todos[action.payload.id],
						text: action.payload.text
					}
				}
			};
		case MODIFY_NOTE:
			return {
				...state,
				note: action.payload
			};
		default:
			return state;
	}
};
