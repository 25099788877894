import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import {
	searchCategory,
	selectCategory,
	searchResults,
	addFavorite,
	loadMoreResults,
	loadMoreCategory,
	selectProvider,
} from '../../actions/AppActions';

import Listing from './listing.js';
import SearchForm from '../SearchForm';
import ProviderSelect from './ProviderSelect.js';
import AmazonAds from './AmazonAds.js';

import loadingGif from '../../images/loading.gif';

import './style.scss';

function Listings( {
	selected_category,
	selected_provider,
	searching,
	results,
	selectedNetworks,
	selectProvider,
	searchCategory,
	loadMoreCategory,
	favorites,
	addFavorite,
	page,
	loadingMore,
	hasMore,
} ) {
	const history = useHistory();

	// inifinite scroll
	useEffect( () => {
		window.addEventListener( 'scroll', hanldeScroll );

		return function cleanup() {
			window.removeEventListener( 'scroll', hanldeScroll );
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selected_category, results, searching, loadingMore] );

	// Initinite Scroll Handler
	var hanldeScroll = function( e ) {
		if( window.document.body.scrollHeight - window.innerHeight <= window.scrollY ) {
			if(
				selected_category !== 'favorites' &&
				selected_category !== 'top' &&
				results.length > 0 &&
				!searching &&
				!loadingMore &&
				hasMore
			) {
				loadMoreCategory( selected_category, page + 1 );
			}
		}
	};

	// Load Results for Subcategory
	var handleProviderChange = function( prov ) {
		selectProvider( prov );
		searchCategory( selected_category );
	};

	// Load Search Results
	var handleSearchSubmit = function( query ) {
		history.push( '/search?q=' + query );
	};

	// Add object to favorites
	var handleFavoriteClick = function( object ) {
		addFavorite( object );
	};

	return (
		<div className="container listings">
			<div className="row py-4">
				<div className="col d-flex align-items-center">
					<Link className="exit" to="/">
						&times;
					</Link>
					{selected_category === 'favorites' ? (
						<p className="m-0">My Favorites</p>
					) : (
						selected_category !== 'all' && (
							<ProviderSelect
								onChange={handleProviderChange}
								selected={selected_provider}
								providers={selectedNetworks[selected_category]}
							/>
						)
					)}
				</div>
				{selected_category !== 'favorites' && (
					<div className="col d-flex justify-content-end">
						<SearchForm
							defaultValue=""
							reset={selected_category}
							onSubmit={handleSearchSubmit}
							placeholder="Search News"
						/>
					</div>
				)}
			</div>
			{searching ? (
				<div className="loading">
					<img src={loadingGif} width={200} />
				</div>
			) : selected_category === 'favorites' ? (
				favorites.length ? (
					<>
						{/* <div className="row pb-4">
							<AmazonAds />
						</div> */}
						<div className="row">
							{favorites.map( ( result, idx ) => (
								<div key={result.id} className="col-lg-3 col-md-4 col-sm-6">
									<Listing
										key={result.id}
										onFavoriteClick={handleFavoriteClick}
										object={result}
									/>
								</div>
							) )}
						</div>
					</>
				) : (
					<div className="not-found favorites">
						No Favorites Found
						<br />
						Select A Category to Start
					</div>
				)
			) : results.length ? (
				<>
					{/* <div className="row pb-4">
						<AmazonAds />
					</div> */}
					<div className="row">
						{results.map( ( result, idx ) => (
							<div key={result.id} className="col-lg-3 col-md-4 col-sm-6">
								<Listing
									key={result.id}
									onFavoriteClick={handleFavoriteClick}
									object={result}
								/>
							</div>
						) )}
					</div>
				</>
			) : (
				<div className="not-found">
					No Results Found
					<br />
					Please Search Again.
				</div>
			)}
			{loadingMore && (
				<div className="loading">
					<img src={loadingGif} width={200} />
				</div>
			)}
		</div>
	);
}

const mapStateToProps = ( state ) => {
	return {
		selected_category: state.app.selected_category,
		selected_provider: state.app.selected_provider,
		searching: state.app.searching,
		results: state.app.results,
		favorites: state.app.favorites,
		hasMore: state.app.hasMore,
		loadingMore: state.app.loadingMore,
		page: state.app.page,
		selectedNetworks: state.app.selectedNetworks,
	};
};

export default connect( mapStateToProps, {
	selectCategory,
	searchCategory,
	searchResults,
	addFavorite,
	loadMoreResults,
	loadMoreCategory,
	selectProvider,
} )( Listings );
