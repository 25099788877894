import { SET_QUOTE, ADD_SHORTCUT, REMOVE_SHORTCUT, SET_REMOTE_SHORTCUTS } from '../actions/types';

const INITIAL_STATE = {
	remote_shortcuts: {},
	shortcuts: {},
	search: '',
	quote:
		"Don't talk about what you have done or what you are going to do. - by Thomas Jefferson"
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_QUOTE:
			return { ...state, quote: action.payload };
		case ADD_SHORTCUT:
			return {
				...state,
				shortcuts: {
					...state.shortcuts,
					[`shortcut${Math.floor(Math.random() * 10000 + 1)}`]: {
						text: action.payload.text,
						url: action.payload.URL,
						editable: true
					}
				}
			};
		case REMOVE_SHORTCUT:
			let shortcuts = { ...state.shortcuts };
			delete shortcuts[action.payload];
			return { ...state, shortcuts };
		case SET_REMOTE_SHORTCUTS:
			return {
				...state,
				remote_shortcuts: action.payload
			}
		default:
			return state;
	}
};
