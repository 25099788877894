import {
	GET_CATEGORIES,
	GET_ALL_CATEGORIES,
	SELECT_CATEGORY,
	GET_PROVIDERS,
	SELECT_PROVIDER,
	SEARCHING_RESULTS,
	SEARCHING_MORE_RESULTS,
	APP_SEARCH_RESULTS,
	APP_SEARCH_MORE_RESULTS,
	ADD_FAVORITE,
	SET_APP_TOKEN,
	UPDATE_CATEGORIES,
	SET_SELECTED_CATEGORIES,
	SET_SELECTED_NETWORKS,
} from '../actions/types';

const INITIAL_STATE = {
	categories: [],
	allCategories: null,
	selected_category: null,
	providers: [],
	selected_provider: null,
	searching: false,
	results: [],
	hasMore: false,
	page: 1,
	loadingMore: false,
	favorites: [],
	favoriteIDs: [],
	token: '',
	selectedCategories: {
		usa: 'United States',
	},
	selectedNetworks: {
		usa: [
			{
				name: 'HuffPost',
				slug: 'huffpost',
				icon: 'huffpost',
			},
			{
				name: 'USA Today',
				slug: 'usatoday',
				icon: 'usatoday',
			},
			{
				name: 'WIVB TV',
				slug: 'wivbtv',
				icon: 'wivbtv',
			},
			{
				name: 'CBD Chicago',
				slug: 'cbd',
				icon: 'cbd',
			},
			{
				name: 'CBS DFW',
				slug: 'cbs',
				icon: 'cbs',
			},
			{
				name: 'Brait Bart',
				slug: 'brait',
				icon: 'brait',
			},
			{
				name: 'Los Angeles Times',
				slug: 'latimes',
				icon: 'latimes',
			},
			{
				name: 'ABC News',
				slug: 'abc',
				icon: 'abc',
			},
		],
	},
};

export default ( state = INITIAL_STATE, action ) => {
	switch( action.type ) {
		case GET_CATEGORIES:
			return {
				...state,
				categories: action.payload,
			};
		case GET_ALL_CATEGORIES:
			return {
				...state,
				allCategories: action.payload,
			};
		case SELECT_CATEGORY:
			return {
				...state,
				selected_category: action.payload,
			};
		case GET_PROVIDERS:
			return {
				...state,
				providers: action.payload,
			};
		case SELECT_PROVIDER:
			return {
				...state,
				selected_provider: action.payload,
			};
		case SEARCHING_RESULTS:
			return {
				...state,
				searching: true,
			};
		case SEARCHING_MORE_RESULTS:
			return {
				...state,
				loadingMore: true,
			};
		case APP_SEARCH_RESULTS:
			if( action.payload == undefined ) {
				action.payload = [];
			}
			return {
				...state,
				searching: false,
				results: action.payload,
				hasMore: action.payload.length > 0,
				page: 1,
			};
		case APP_SEARCH_MORE_RESULTS:
			return {
				...state,
				loadingMore: false,
				results: [...state.results, ...action.payload],
				hasMore: action.payload.length > 0,
				page: state.page + 1,
			};
		case ADD_FAVORITE:
			return {
				...state,
				favorites: action.payload,
				favoriteIDs: action.payload.map( ( el ) => el.id ),
			};
		case SET_APP_TOKEN:
			return {
				...state,
				token: action.payload,
			};
		case UPDATE_CATEGORIES:
			return {
				...state,
				categories: INITIAL_STATE.categories,
				categoriesVersion: 1,
			};
		case SET_SELECTED_CATEGORIES:
			return {
				...state,
				selectedCategories: action.payload,
			};
		case SET_SELECTED_NETWORKS:
			return {
				...state,
				selectedNetworks: action.payload,
			};
		default:
			return state;
	}
};
