import { SET_WEATHER_API, SET_COORDINATES, SET_UNITS, SET_CITY, SET_STATE } from './types';

const API_URL = (city, units, lat, lng) => {
	return `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lng}&units=${units}&APPID=9ce175c467d3ee9b63148b5e4065dcd7`;
};

function getUserLocation(dispatch, callback) {
	if(window.geoip2) {
		window.geoip2.city(function(res){
			if(!res || !res.location){
				dispatch(setUserLocation('New York','New York', { lng: -73.98, lat: 40.76 }))
				callback('New York', { lng: -73.98, lat: 40.76 });
			} else {
				dispatch(setUserLocation(
					res.city.names.en,
					res.subdivisions ? res.subdivisions[0].names.en : res.country.names.en,
					{ 
						lat: res.location.latitude , 
						lng: res.location.longitude 
					}
				))
				callback(res.city.names.en, { 
					lat: res.location.latitude , 
					lng: res.location.longitude 
				});
			}
		}, function(err){
			dispatch(setUserLocation('New York','New York', { lng: -73.98, lat: 40.76 }))
			callback('New York', { lng: -73.98, lat: 40.76 });
		});
	} else {
		// No geoip set Default Location
		dispatch(setUserLocation('New York','New York', { lng: -73.98, lat: 40.76 }))
		callback('New York', { lng: -73.98, lat: 40.76 });
	}
}

function getWeatherInformation(city, units, coordinates, callback) {
	fetch(API_URL(city, units, coordinates.lat, coordinates.lng))
			.then(response => response.json())
			.then(response => {
				callback(response);
			});
}

export const getWeather = () => {
	return (dispatch, getState) => {
		var { units, city, state, coordinates } = getState().weather;
		if(!coordinates || !state || !city){
			getUserLocation(dispatch, function(userCity, userCoordinates) {
				getWeatherInformation(userCity, units, userCoordinates, function(response) {
					dispatch({ type: SET_WEATHER_API, payload: response });
				});
			});
		} else {
			getWeatherInformation(city, units, coordinates, function(response) {
				dispatch({ type: SET_WEATHER_API, payload: response });
			});
		}
	};
}; 

export const setUserLocation = (city, state, location) => {
	return dispatch => {
		dispatch(setCity(city));
		dispatch(setState(state));
		dispatch(setCoordinates(location));
	};
}

export const setCity = value => {
	return { type: SET_CITY, payload: value };
};

export const setState = value => {
	return { type: SET_STATE, payload: value };
};

export const setCoordinates = data => {
	return { type: SET_COORDINATES, payload: data };
};

export const setUnits = value => {
	return { type: SET_UNITS, payload: value };
};
