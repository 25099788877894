import {
	GET_CATEGORIES,
	GET_ALL_CATEGORIES,
	SELECT_CATEGORY,
	SEARCHING_RESULTS,
	SEARCHING_MORE_RESULTS,
	APP_SEARCH_RESULTS,
	APP_SEARCH_MORE_RESULTS,
	ADD_FAVORITE,
	SET_APP_TOKEN,
	SELECT_PROVIDER,
	GET_PROVIDERS,
	UPDATE_CATEGORIES,
	SET_SELECTED_CATEGORIES,
	SET_SELECTED_NETWORKS,
} from './types';
import axios from 'axios';
import Cookies from 'js-cookie';

import { dashboard, mainSite } from '../util';

export const updateCategories = () => {
	return {
		type: UPDATE_CATEGORIES,
	};
};

export const getCategories = () => {
	return ( dispatch ) => {
		axios.get( dashboard + '/api/categories' ).then( ( res ) => {
			dispatch( {
				type: GET_CATEGORIES,
				payload: res.data,
			} );
		} );
	};
};

export const getAllCategories = () => {
	return ( dispatch ) => {
		axios.get( dashboard + '/api/categories/all' ).then( ( res ) => {
			dispatch( {
				type: GET_ALL_CATEGORIES,
				payload: res.data,
			} );
		} );
	};
};

export const getProviders = () => {
	return ( dispatch ) => {
		axios.get( dashboard + '/api/providers' ).then( ( res ) => {
			dispatch( {
				type: GET_PROVIDERS,
				payload: res.data,
			} );
		} );
	};
};

export const searchCategory = ( value ) => {
	return ( dispatch, getState ) => {
		const provider = getState().app.selected_provider;
		const selectedNetworks = getState().app.selectedNetworks;

		dispatch( {
			type: APP_SEARCH_RESULTS,
			payload: [],
		} );

		if( value !== 'favorites' ) {
			dispatch( {
				type: SEARCHING_RESULTS,
				payload: true,
			} );

			// let url = `${dashboard}/api/news?categories[${value}]`;
			let url = `${dashboard}/api/news`;
			let categories = {};
			if( value == 'all' ) {
				for( var category in selectedNetworks ) {
					categories[category] = selectedNetworks[category].map( network => network.slug );
				}
			} else if( provider ) {
				categories[value] = [provider];
			} else {
				let providers = selectedNetworks[value].map( network => network.slug );
				categories[value] = providers;
			}
			axios.get( url, {
				params: {
					categories: categories
				}
			} ).then( ( res ) => {
				dispatch( {
					type: APP_SEARCH_RESULTS,
					payload: res.data.data,
				} );
			} );
		}
	};
};

export const loadMoreCategory = ( value, page ) => {
	return ( dispatch, getState ) => {
		const provider = getState().app.selected_provider;
		const selectedNetworks = getState().app.selectedNetworks;

		dispatch( {
			type: SEARCHING_MORE_RESULTS,
			payload: true,
		} );

		let url = `${dashboard}/api/news`;
		let categories = {};
		if( value == 'all' ) {
			for( var category in selectedNetworks ) {
				categories[category] = selectedNetworks[category].map( network => network.slug );
			}
		} else if( provider ) {
			categories[value] = [provider];
		} else {
			let providers = selectedNetworks[value].map( network => network.slug );
			categories[value] = providers;
		}
		axios.get( url, {
			params: {
				categories: categories,
				page: page
			}
		} ).then( ( res ) => {
			dispatch( {
				type: APP_SEARCH_MORE_RESULTS,
				payload: res.data.data,
			} );
		} );
	};
};

export const searchResults = ( query ) => {
	return ( dispatch, getState ) => {
		const category = getState().app.selected_category;

		dispatch( {
			type: APP_SEARCH_RESULTS,
			payload: [],
		} );
		dispatch( {
			type: SEARCHING_RESULTS,
			payload: true,
		} );

		axios
			.get( dashboard + '/api/search', {
				params: {
					query: query,
					category: category && category,
				},
			} )
			.then( ( res ) => {
				dispatch( {
					type: APP_SEARCH_RESULTS,
					payload: res.data.data,
				} );
			} );
	};
};

export const loadMoreResults = ( query, page ) => {
	return ( dispatch, getState ) => {
		const category = getState().app.selected_category;

		dispatch( {
			type: SEARCHING_MORE_RESULTS,
			payload: true,
		} );

		axios
			.get( dashboard + '/api/search', {
				params: {
					query: query,
					category: category && category,
					page: page
				},
			} )
			.then( ( res ) => {
				dispatch( {
					type: APP_SEARCH_MORE_RESULTS,
					payload: res.data.data,
				} );
			} );
	};
};

export const selectCategory = ( value ) => {
	return {
		type: SELECT_CATEGORY,
		payload: value,
	};
};

export const selectProvider = ( value ) => {
	return {
		type: SELECT_PROVIDER,
		payload: value,
	};
};

export const addFavorite = ( value ) => {
	return ( dispatch, getState ) => {
		axios
			.post( dashboard + `/api/favorites/add`, {
				uid: getState().app.token,
				object: value.id,
			} )
			.then( ( res ) => {
				dispatch( {
					type: ADD_FAVORITE,
					payload: res.data,
				} );
			} );
	};
};

export const getFavorites = () => {
	return ( dispatch, getState ) => {
		axios
			.get( dashboard + `/api/favorites`, {
				params: {
					uid: getState().app.token,
				},
			} )
			.then( ( res ) => {
				dispatch( {
					type: ADD_FAVORITE,
					payload: res.data,
				} );
			} );
	};
};

// helper not action
export const getSingle = ( id, callback ) => {
	axios.get( dashboard + `/api/single/${id}` ).then( ( res ) => {
		callback( res.data );
	} );
};

// helper not action
export const getReviews = ( id, callback ) => {
	axios.get( dashboard + `/api/single/${id}/reviews` ).then( ( res ) => {
		callback( res.data );
	} );
};

// helper not action
export const getAutocompleteSuggestions = ( query, callback ) => {
	axios
		.get( dashboard + '/api/autocomplete', {
			params: {
				q: query,
			},
		} )
		.then( ( res ) => {
			callback( res.data );
		} );
};

// Token is the only link between favorited news
// It's used to connect the extension with the main site
export const createAppToken = () => {
	return ( dispatch ) => {
		if( Cookies.get( 'app_token' ) ) {
			var tkn = Cookies.get( 'app_token' );
			// Refresh cookie
			var TokenPixel = new Image();
			TokenPixel.src = `${mainSite}/tkn.php?tkn=${tkn}`;
			document.body.appendChild( TokenPixel );

			dispatch( {
				type: SET_APP_TOKEN,
				payload: tkn,
			} );

			dispatch( getFavorites() );
		} else {
			var uid = localStorage.getItem( 'uid' );
			// Refresh cookie
			var TokenPixel = new Image();
			TokenPixel.src = `${mainSite}/tkn.php?tkn=${uid}`;
			document.body.appendChild( TokenPixel );

			dispatch( {
				type: SET_APP_TOKEN,
				payload: uid,
			} );
		}
	};
};

export const setSelectedCategories = ( categories ) => {
	return {
		type: SET_SELECTED_CATEGORIES,
		payload: categories,
	};
};

export const setSelectedNetworks = ( networks ) => {
	return {
		type: SET_SELECTED_NETWORKS,
		payload: networks,
	};
};
