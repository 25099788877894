import {
	GET_TRACKING_RESULT,
	GET_TRACKING_RESULT_SUCCESS,
	GET_TRACKING_RESULT_ERROR,
} from '../actions/types';

const INITIAL_STATE = {
	result: [],
	error: false,
	isLoading: false,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case GET_TRACKING_RESULT:
			return { ...state, isLoading: true, result: [], error: false };
		case GET_TRACKING_RESULT_SUCCESS:
			return { ...state, isLoading: false, result: action.payload };
		case GET_TRACKING_RESULT_ERROR:
			return { ...state, isLoading: false, result: [], error: action.payload };
		default:
			return state;
	}
};
