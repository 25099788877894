import React, { useState } from 'react';

function ShortCut({ item, last = null, onAddShortCut, onRemove }) {
	const [text, setText] = useState('');
	const [URL, setURL] = useState('');

	const getDomain = (url, favicon = '') => {
		if(favicon !== '')
			return favicon;

		var r = /:\/\/(.[^/]+)/;
		return url.match(r)[1];
	};

	const addShortcut = () => {
		if (!text) {
			alert('Please enter a valid name.');
			return;
		}
		if (!URL) {
			alert('Please enter a valid URL.');
			return;
		}

		onAddShortCut(text, URL, (success) => {
			if (success) {
				setText('');
				setURL('');
				document.body.className = '';
			}
		});
	};

	const toggleModal = (modal) => {
		document.body.classList.add('modal-open');
		document.body.classList.add(modal);
	};

	if (!last) {
		return (
			<div className="wrap">
				<a href={item.url} className="item" target="_blank">
					<span className="icon">
						<img
							width="18"
							src={`https://s2.googleusercontent.com/s2/favicons?domain_url=${getDomain(
								item.url,
								item.favicon
							)}`}
							alt={item.text}
						/>
					</span>
					<p>{item.text}</p>
				</a>
				{item.editable && (
					<button className="remove-shortcut" onClick={() => onRemove(item.id)}>
						<i>x</i>
					</button>
				)}
			</div>
		);
	}

	return (
		<div className="wrap">
			<button className="item" onClick={() => toggleModal('add-shortcut-open')}>
				<span className="icon">{item.icon}</span>
				<p>{item.text}</p>
			</button>
			<div className="shortcut-popup">
				<input
					placeholder="Name"
					className="form-control"
					type="text"
					autoFocus
					value={text}
					onChange={(e) => setText(e.target.value)}
				/>
				<input
					placeholder="URL"
					className="form-control"
					type="text"
					value={URL}
					onChange={(e) => setURL(e.target.value)}
				/>

				<button onClick={addShortcut} className="btn">
					Add Shortcut
				</button>
			</div>
		</div>
	);
}

export default ShortCut;
