import React from 'react';
import ReactDOM from 'react-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import App from './App';
import { store, persistor } from './store';

import * as serviceWorker from './serviceWorker';

import './index.scss';

if( window.location.search.substr( 1 ) === 'reset' ) {
	setInterval( () => {
		localStorage.removeItem( 'persist:root' );
		localStorage.removeItem( 'persist:app' );
		localStorage.removeItem( 'display_keep_it1' );
		window.location = '/';
	}, 200 );
} else {
	ReactDOM.render(
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<App />
			</PersistGate>
		</Provider>,
		document.getElementById( 'root' )
	);

	// If you want your app to work offline and load faster, you can change
	// unregister() to register() below. Note this comes with some pitfalls.
	// Learn more about service workers: https://bit.ly/CRA-PWA
	serviceWorker.unregister();
}
