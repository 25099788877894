import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Icon } from 'react-icons-kit';
import { check } from 'react-icons-kit/fa/check';

import { setSelectedCategories, setSelectedNetworks } from '../../actions/AppActions';

import './style.scss';

let allCategories = [
	{
		name: 'United States',
		slug: 'usa',
		networks: [
			{
				name: 'HuffPost',
				slug: 'huffpost',
				icon: 'huffpost',
			},
			{
				name: 'USA Today',
				slug: 'usatoday',
				icon: 'usatoday',
			},
			{
				name: 'WIVB TV',
				slug: 'wivbtv',
				icon: 'wivbtv',
			},
			{
				name: 'CBD Chicago',
				slug: 'cbd',
				icon: 'cbd',
			},
			{
				name: 'CBS DFW',
				slug: 'cbs',
				icon: 'cbs',
			},
			{
				name: 'Brait Bart',
				slug: 'brait',
				icon: 'brait',
			},
			{
				name: 'Los Angeles Times',
				slug: 'latimes',
				icon: 'latimes',
			},
			{
				name: 'ABC News',
				slug: 'abc',
				icon: 'abc',
			},
		],
	},
	{
		name: 'World',
		slug: 'world',
		networks: [
			{
				name: 'HuffPost',
				slug: 'huffpost',
				icon: 'huffpost',
			},
			{
				name: 'BuzzFeed',
				slug: 'buzzfeed',
				icon: 'buzzfeed',
			},
			{
				name: 'CNN',
				slug: 'cnn',
				icon: 'cnn',
			},
			{
				name: 'ABC News',
				slug: 'abc',
				icon: 'abc',
			},
			{
				name: 'NDTV News',
				slug: 'ndtv',
				icon: 'ndtv',
			},
			{
				name: 'The Guardian',
				slug: 'guardian',
				icon: 'guardian',
			},
			{
				name: 'Washington Post',
				slug: 'wapost',
				icon: 'wapost',
			},
			{
				name: 'RT',
				slug: 'rt',
				icon: 'rt',
			},
			{
				name: 'Raw Story',
				slug: 'raw',
				icon: 'raw',
			},
			{
				name: 'Washington Times',
				slug: 'watimes',
				icon: 'watimes',
			},
		],
	},
	{
		name: 'Sports',
		slug: 'sports',
		networks: [
			{
				name: 'Huffpost',
				slug: 'huffpost',
				icon: 'huffpost',
			},
			{
				name: 'ESPN ',
				slug: 'espn',
				icon: 'espn',
			},
			{
				name: 'Fox Sports',
				slug: 'fox',
				icon: 'fox',
			},
			{
				name: 'Rivals',
				slug: 'rivals',
				icon: 'rivals',
			},
			{
				name: 'Sports Net',
				slug: 'sportsnet',
				icon: 'sportsnet',
			},
			{
				name: 'Esentially Sports',
				slug: 'esentially',
				icon: 'esentially',
			},
			{
				name: 'Nine.com.au',
				slug: 'nine',
				icon: 'nine',
			},
		],
	},
	{
		name: 'Tec',
		slug: 'tec',
		networks: [
			{
				name: 'HuffPost',
				slug: 'huffpost',
				icon: 'huffpost',
			},
			{
				name: 'Cnet',
				slug: 'cnet',
				icon: 'cnet',
			},
			{
				name: 'Computer World',
				slug: 'computer',
				icon: 'computer',
			},
			{
				name: 'Digital Trends',
				slug: 'digital',
				icon: 'digital',
			},
			{
				name: 'Tec News World',
				slug: 'tecnews',
				icon: 'tecnews',
			},
			{
				name: 'Tech Republic',
				slug: 'techrepublic',
				icon: 'techrepublic',
			},
			{
				name: 'Tec Radar',
				slug: 'tecradar',
				icon: 'tecradar',
			},
		],
	},
	{
		name: 'Fashion',
		slug: 'fashion',
		networks: [
			{
				name: 'HuffPost',
				slug: 'huffpost',
				icon: 'huffpost',
			},
			{
				name: 'Allure',
				slug: 'allure',
				icon: 'allure',
			},
			{
				name: 'E!',
				slug: 'e',
				icon: 'e',
			},
			{
				name: 'ET',
				slug: 'et',
				icon: 'et',
			},
			{
				name: 'Los Angeles Times',
				slug: 'latimes',
				icon: 'latimes',
			},
			{
				name: 'Elle',
				slug: 'elle',
				icon: 'elle',
			},
			{
				name: 'GQ',
				slug: 'gq',
				icon: 'gq',
			},
			{
				name: 'Refinery29',
				slug: 'refinery',
				icon: 'refinery',
			},
			{
				name: 'Pop Sugar',
				slug: 'popsugar',
				icon: 'popsugar',
			},
		],
	},
	{
		name: 'Business',
		slug: 'bizz',
		networks: [
			{
				name: 'HuffPost',
				slug: 'huffpost',
				icon: 'huffpost',
			},
			{
				name: 'Entrepreneur',
				slug: 'entrepreneur',
				icon: 'entrepreneur',
			},
			{
				name: 'Black Enterprise',
				slug: 'black',
				icon: 'black',
			},
			{
				name: 'INC',
				slug: 'inc',
				icon: 'inc',
			},
			{
				name: 'Benzinga',
				slug: 'benzinga',
				icon: 'benzinga',
			},
			{
				name: 'Business Blog O2',
				slug: 'o2',
				icon: 'o2',
			},
		],
	},
	{
		name: 'Health',
		slug: 'health',
		networks: [
			{
				name: 'HuffPost',
				slug: 'huffpost',
				icon: 'huffpost',
			},
			{
				name: 'Mens Journal',
				slug: 'mens',
				icon: 'mens',
			},
			{
				name: 'Wonderlust',
				slug: 'wonderlust',
				icon: 'wonderlust',
			},
			{
				name: 'Web MD',
				slug: 'webmd',
				icon: 'webmd',
			},
			{
				name: 'DHMG Blog',
				slug: 'dhmg',
				icon: 'dhmg',
			},
			{
				name: 'kHN',
				slug: 'khn',
				icon: 'khn',
			},
			{
				name: 'The Complete Herbal Guide',
				slug: 'herbal',
				icon: 'herbal',
			},
		],
	},
	{
		name: 'Entertainment',
		slug: 'ent',
		networks: [
			{
				name: 'HuffPost',
				slug: 'huffpost',
				icon: 'huffpost',
			},
			{
				name: 'E!',
				slug: 'e',
				icon: 'e',
			},
			{
				name: 'CNN',
				slug: 'cnn',
				icon: 'cnn',
			},
			{
				name: 'Hollywood Live',
				slug: 'hollywood',
				icon: 'hollywood',
			},
			{
				name: 'MTO News',
				slug: 'mto',
				icon: 'mto',
			},
			{
				name: 'BuzzFeed',
				slug: 'buzzfeed',
				icon: 'buzzfeed',
			},
			{
				name: 'DeadLine',
				slug: 'deadline',
				icon: 'deadline',
			},
			{
				name: 'Celebrity Insider',
				slug: 'celebrity',
				icon: 'celebrity',
			},
		],
	},
	{
		name: 'Lifestyle',
		slug: 'lifestyle',
		networks: [
			{
				name: 'HuffPost',
				slug: 'huffpost',
				icon: 'huffpost',
			},
			{
				name: 'ET',
				slug: 'et',
				icon: 'et',
			},
			{
				name: 'Primer Magazine',
				slug: 'primer',
				icon: 'primer',
			},
			{
				name: 'Grit Daily',
				slug: 'grit',
				icon: 'grit',
			},
			{
				name: 'Wit and Delight',
				slug: 'wit',
				icon: 'wit',
			},
			{
				name: 'Social',
				slug: 'social',
				icon: 'social',
			},
			{
				name: 'Scout The City',
				slug: 'scout',
				icon: 'scout',
			},
			{
				name: 'Swag Living',
				slug: 'swag',
				icon: 'swag',
			},
			{
				name: 'Blended Like Us',
				slug: 'blend',
				icon: 'blend',
			},
		],
	},
];

function NetworkSelect( {
	selectedCategories,
	selectedNetworks,
	setSelectedCategories,
	setSelectedNetworks,
	onSubmit
} ) {
	var addSelectedCategory = function( categorySlug, categoryName ) {
		let categories = { ...selectedCategories };
		let networks = { ...selectedNetworks };

		if( categorySlug in categories ) {
			// remove
			delete categories[categorySlug];
			// remove selected networks
			delete networks[categorySlug];
		} else {
			// add
			categories[categorySlug] = categoryName;
			// create selected networks
			const category = allCategories.find( ( element ) => element.slug === categorySlug );
			networks[categorySlug] = category.networks;
		}
		setSelectedCategories( categories );
		setSelectedNetworks( networks );
	};

	var addSelectedNetwork = function( category, network, areAllSelected ) {
		let networks = { ...selectedNetworks };
		if( areAllSelected ) {
			networks[category] = [];
		}

		let networkExists = networks[category].find( ( element ) => element.slug === network.slug );
		if( networkExists ) {
			// remove
			let newNetworks = networks[category].filter( ( element ) => element.slug !== network.slug );
			networks[category] = newNetworks;
		} else {
			// add
			networks[category].push( network );
		}
		setSelectedNetworks( networks );
	};

	var selectAllNetworks = function( categorySlug ) {
		let networks = { ...selectedNetworks };
		const category = allCategories.find( ( element ) => element.slug === categorySlug );
		networks[categorySlug] = category.networks;
		setSelectedNetworks( networks );
	};

	return (
		<div className="network-select">
			{allCategories.map( ( item, i ) => {
				var isCategorySelected = item.slug in selectedCategories;
				var areAllNetworksSelected = false;

				if(
					isCategorySelected &&
					item.networks.length === selectedNetworks[item.slug].length
				) {
					areAllNetworksSelected = true;
				}
				return (
					<div key={i} className="network-select-category">
						<p
							onClick={() => addSelectedCategory( item.slug, item.name )}
							className={isCategorySelected ? 'selected' : ''}
							style={{ backgroundImage: `url(categories/${item.slug}.png)` }}
						>
							{item.name}
							<Icon className="icon" size="24" icon={check} />
						</p>
						<ul className={isCategorySelected ? 'open' : ''}>
							<li
								className={areAllNetworksSelected ? 'selected' : ''}
								onClick={() => selectAllNetworks( item.slug )}
							>
								<div className="img-wrap">
									<img src={`networks/all.png`} />
								</div>
								<div className="label">Select All</div>
							</li>
							{item.networks.map( ( network, j ) => {
								let selectedClass = '';
								if( !areAllNetworksSelected && !!selectedNetworks[item.slug] ) {
									let foundNetwork = selectedNetworks[item.slug].find(
										( element ) => element.slug === network.slug
									);
									if( foundNetwork ) {
										selectedClass = 'selected';
									}
								}
								return (
									<li
										className={selectedClass}
										onClick={() =>
											addSelectedNetwork(
												item.slug,
												network,
												areAllNetworksSelected
											)
										}
										key={i + '_' + j}
									>
										<div className="img-wrap">
											<img src={`networks/${network.icon}.png`} />
										</div>
										<div className="label">{network.name}</div>
									</li>
								);
							} )}
						</ul>
					</div>
				);
			} )}

			<button onClick={onSubmit}>
				Save & Continue
			</button>
		</div>
	);
}

const mapStateToProps = ( state ) => {
	return {
		selectedCategories: state.app.selectedCategories,
		selectedNetworks: state.app.selectedNetworks,
	};
};

export default connect( mapStateToProps, { setSelectedCategories, setSelectedNetworks } )(
	NetworkSelect
);
