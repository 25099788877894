import axios from 'axios';
import { dashboard } from '../util';
import { TOGGLE_TOP_BAR_COLLAPSE, TOP_BAR_HIT, RESET_TOP_BAR_INTERACTIONS } from './types';

const SETTINGS_URL = dashboard + '/settings';
const INTERACTIONS_URL = dashboard + '/topbar';

export const toggleTopBarCollapse = ( by ) => {
	return {
		type: TOGGLE_TOP_BAR_COLLAPSE,
		payload: by,
	};
};

export const resetTopBarInteractions = ( by ) => {
	return ( dispatch, getState ) => {
		dispatch( { type: RESET_TOP_BAR_INTERACTIONS, payload: by } );

		axios
			.get( `${INTERACTIONS_URL}?u=${getState().app.token}` )
			.then( ( res ) => {
				console.log( res.data );
			} )
			.catch( ( err ) => {
				console.log( err );
			} );
	};
};

export const checkTopBarSettings = () => {
	return ( dispatch, getState ) => {
		const { collapsed, collapsedBy, hits, lastInteraction } = getState().topBar;
		dispatch( { type: TOP_BAR_HIT } );

		fetch( SETTINGS_URL )
			.then( ( response ) => response.json() )
			.then( ( response ) => {
				const { hideTopBar, hideTopBarBy, hideTopBarFrequency } = response;

				// CASE #1
				// hideTopBar is true from the dashboard
				// Action => Check if topbar is open to continue
				if( hideTopBar ) {
					if( !collapsed ) {
						switch( hideTopBarBy ) {
							// CASE #3
							// dash want to close topBar by hits
							case 'hits':
								if( hits >= hideTopBarFrequency ) {
									dispatch( { type: TOGGLE_TOP_BAR_COLLAPSE, payload: 'dash' } );
								}
								break;
							// CASE #4
							// dash want to close topBar after n days
							case 'days':
								let days = ( Date.now() - lastInteraction ) / ( 1000 * 3600 * 24 );
								if( days >= hideTopBarFrequency ) {
									dispatch( { type: TOGGLE_TOP_BAR_COLLAPSE, payload: 'dash' } );
								}
								break;
						}
					}
				}
				// CASE #2
				// hideTopBar is false from the dashboard
				// Action => Open the topBar if was closed by the dashboard, if was closed by the user, repect that
				else {
					if( collapsed && collapsedBy === 'dash' ) {
						dispatch( { type: TOGGLE_TOP_BAR_COLLAPSE, payload: 'dash' } );
					}
				}
			} );
	};
};
