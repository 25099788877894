import React, { useEffect } from 'react';

function ProviderSelect( { selected, providers, onChange } ) {
    var hanldeChange = function( e ) {
        if( !e.target.value ) {
            onChange( null );
        } else {
            onChange( providers[e.target.value].slug );
        }
    }

    return providers.length > 0 ? (
        <select onChange={hanldeChange} defaultValue={selected ? selected.slug : ""} className="custom-select">
            <option value="">All Networks</option>
            {providers.map( ( prov, i ) => (
                <option key={i} data-pid={prov.slug} value={i}>{prov.name}</option>
            ) )}
        </select>
    ) : '';
}

export default ProviderSelect;