import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { Link } from 'react-router-dom';

import Categories from '../../components/Categories/List';
import NewsForm from '../../components/NewsForm';

import { setPreferencesInit } from '../../actions/PreferencesActions';
import { resetTopBarInteractions } from '../../actions/TopBarActions';

import { dashboard } from '../../util';

import './style.scss';
import NetworkSelect from '../../components/NetworkSelect';

function Intro( { resetTopBarInteractions, setPreferencesInit, categories, preferences, ...props } ) {
	let location = useLocation();
	const history = useHistory();

	useEffect( () => {
		resetTopBarInteractions( 'user' );
	}, [] );

	useEffect( () => {
		if( !preferences ) {
			// first run
			const first_search = localStorage.getItem( 'first_search' );
			if( first_search ) {
				// Found cookie search
				setPreferencesInit( true );
				history.push( '/search?q=' + first_search );
			}
		}
	}, [preferences] );

	var handleExitClick = function( e ) {
		setPreferencesInit( true );
	};

	// Load Search Results
	var handleSearchSubmit = function() {
		setPreferencesInit( true );
		history.push( '/listings/all' );
	};

	return (
		<div>
			<div
				style={
					props.showBg
						? props.bg
							? { backgroundImage: `url('${props.bg}')` }
							: {
								backgroundImage: `url('${dashboard}/background')`,
							}
						: null
				}
				className={`toolbar intro-page${props.showBg ? ' bg' : ''}${props.darkMode ? ' dark' : ''
					}`}
			>
				<section className="hero">
					<div className="container">
						<h1>
							{location.pathname == '/intro'
								? 'Stay Up to Date, Start Now.'
								: 'Online News App Installed!'}
						</h1>
					</div>
				</section>

				<section className="intro">
					<div className={`container`}>
						<Link to="/" onClick={handleExitClick} className="exit">
							&times;
						</Link>

						<div className="text-center mb-5">
							<h2>Select your favorite Networks</h2>
						</div>
						<NetworkSelect onSubmit={handleSearchSubmit} />
					</div>
				</section>
			</div>
		</div>
	);
}

const mapStateToProps = ( state ) => {
	return {
		bg: state.settings.backgroundURL,
		showBg: state.settings.options.background.visible,
		darkMode: state.settings.darkMode,
		preferences: state.preferences.init,
	};
};

export default connect( mapStateToProps, {
	setPreferencesInit,
	resetTopBarInteractions,
} )( Intro );
