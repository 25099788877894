import {
	SET_OPTION_SELECTED,
	SET_SINGLE_OPTION_SELECTED,
	REMOVE_OPTION_SELECTED,
	SET_STEP_SELECTED,
	SET_PREFERENCES_INIT,
	SET_COLLAPSED,
} from './types';

export const setStepSelected = (value) => {
	return {
		type: SET_STEP_SELECTED,
		payload: value,
	};
};
export const setOptionSelected = (parent, option) => {
	return {
		type: SET_OPTION_SELECTED,
		payload: { parent, option },
	};
};
export const removeOptionSelected = (parent, option) => {
	return {
		type: REMOVE_OPTION_SELECTED,
		payload: { parent, option },
	};
};
export const setSingleOptionSelected = (parent, option) => {
	return {
		type: SET_SINGLE_OPTION_SELECTED,
		payload: { parent, option },
	};
};
export const setPreferencesInit = (value) => {
	return {
		type: SET_PREFERENCES_INIT,
		payload: value,
	};
};
export const setCollapsed = (value) => {
	return {
		type: SET_COLLAPSED,
		payload: value,
	};
};