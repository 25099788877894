import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { getWeather } from '../../actions/WeatherActions';
import { dashboard } from '../../util';

import './style.scss';

function Weather({ getWeather, weatherData, units, city, state }) {
	
	useEffect(() => {
		if(!weatherData) {
			getWeather();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [weatherData]);

	useEffect(() => {
		if(weatherData) {
			getWeather();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [units, city]);

	if (weatherData === null) 
		return null;

	var uid = localStorage.getItem('uid');

	return (
		<div className="weather">
			<a target="_blank" className="wrap" href={`${dashboard}/weather/?u=${uid}&q=${encodeURIComponent(city + ', ' + state)}`}>
				<p className="temp">
					<img
						alt={weatherData.weather[0].main}
						src={`https://openweathermap.org/img/w/${weatherData.weather[0].icon}.png`}
					/>
					{Math.round(weatherData.main.temp)} &deg;{units === 'metric' ? 'C' : 'F'}
				</p>
				<p className="city">{city}</p>
			</a>
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		weatherData: state.weather.api,
		units: state.weather.units,
		city: state.weather.city,
		state: state.weather.state,
	};
};

export default connect(mapStateToProps, { getWeather  })(Weather);
