import React, { useEffect } from 'react';

import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import Categories from './components/Categories';

import Header from './components/Header';

import Home from './pages/Home/';
import Intro from './pages/Intro/';
import ListingsPage from './pages/Listings/';
import SearchPage from './pages/Listings/Search';

import { createAppToken } from './actions/AppActions';

import './App.scss';
import { connect } from 'react-redux';

function App({ token, createAppToken }) {
	useEffect(() => {
		if (token == '') {
			createAppToken();
		}
	}, [token]);

	return (
		<Router>
			<Header />
			<Categories />
			<Switch>
				<Route path="/intro">
					<Intro />
				</Route>
				<Route path="/listings/:id">
					<ListingsPage />
				</Route>
				<Route path="/search">
					<SearchPage />
				</Route>
				<Route path="/">
					<Home />
				</Route>
			</Switch>
		</Router>
	);
}

var mapStateToProps = (state) => {
	return {
		token: state.app.token,
	};
};

export default connect(mapStateToProps, {
	createAppToken,
})(App);
