import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { Icon } from 'react-icons-kit';
import { heart } from 'react-icons-kit/fa/heart';

function Favorite({ selected_category }) {
	const [selected, setSelected] = useState(false);

	useEffect(() => {
		if (selected_category) {
			if (selected_category !== 'favorites') {
				setSelected(false);
			} else if(!selected) {
				setSelected(true);
			}
		} else {
			setSelected(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selected_category]);

	return (
		<Link
			className={selected ? 'category-item favorites selected' : 'favorites category-item'}
			to="/listings/favorites"
		>
			<Icon size={40} icon={heart} />
			{/* <span>My Favorites</span> */}
		</Link>
	);
}

const mapStateToProps = (state) => {
	return {
		selected_category: state.app.selected_category,
	};
};

export default connect(mapStateToProps, {})(Favorite);
