import {
	SET_WEATHER_API,
	SET_UNITS,
	SET_CITY,
	SET_COORDINATES,
	SET_STATE
} from '../actions/types';

const INITIAL_STATE = {
	api: null,
	units: 'imperial',
	city: '',
	state: '',
	coordinates: null
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_WEATHER_API:
			return { ...state, api: action.payload };
		case SET_UNITS:
			return { ...state, units: action.payload };
		case SET_CITY:
			return { ...state, city: action.payload };
		case SET_STATE:
			return { ...state, state: action.payload };
		case SET_COORDINATES:
			return { ...state, coordinates: action.payload };
		default:
			return state;
	}
};
