import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import NewsForm from '../../components/NewsForm';
import ShortCutsList from '../../components/ShortCutsList';
import { selectCategory } from '../../actions/AppActions';
import { checkTopBarSettings } from '../../actions/TopBarActions';

import { dashboard } from '../../util';

import './style.scss';

function Home({ checkTopBarSettings, selectCategory, darkMode, ...props }) {
	const history = useHistory();

	useEffect(() => {
		selectCategory(null);
		checkTopBarSettings();
	}, []);

	// Load Search Results
	var handleSearchSubmit = function (query) {
		history.push('/search?q=' + query);
	};

	return (
		<div
			style={
				props.showBg
					? props.bg
						? { backgroundImage: `url('${props.bg}')` }
						: { backgroundImage: `url('${dashboard}/background')` }
					: null
			}
			className={`toolbar ${props.showBg ? ' bg' : ''}${darkMode ? ' dark' : ''}`}
		>
			<section className="main-content">
				<h1 className="text-center">Stay Up to Date, Start Now.</h1>
				<NewsForm
					defaultValue=""
					onSubmit={handleSearchSubmit}
					placeholder="Search breaking news"
				/>
				<ShortCutsList />
			</section>
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		bg: state.settings.backgroundURL,
		showBg: state.settings.options.background.visible,
		darkMode: state.settings.darkMode,
	};
};

export default connect(mapStateToProps, {
	selectCategory,
	checkTopBarSettings,
})(Home);
