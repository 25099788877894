import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import './style.scss';

import OwlCarousel from 'react-owl-carousel2';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import Category from './Category.js';
import Favorite from './Favorite.js';

import { getCategories } from '../../actions/AppActions';

function Categories( { categories, getCategories, ...props } ) {
	const location = useLocation();
	return (
		<>
			{Object.keys( categories ).length ? (
				<div className="owl-theme-category">
					<OwlCarousel
						options={{
							nav: true,
							dots: false,
							rewind: false,
							slideBy: 4,
							navText: ['<span>‹</span>', '<span>›</span>'],
							responsive: {
								0: { items: 4 },
								768: { items: 6 },
								1200: { items: 10 },
							},
						}}
					>
						<Category
							cat={{
								Category: 'Settings',
								ID: 'settings',
								icon: require( '../../images/icons/settings.png' ),
								PrimaryImage: false,
							}}
						/>
						<Category
							cat={{
								Category: 'All News',
								ID: 'all',
								icon: require( '../../images/favicon.png' ),
								PrimaryImage: false,
							}}
						/>
						{/* <Category
							cat={{
								Category: 'Browse by Category',
								ID: 'categories',
								PrimaryImage: require('../../images/icons/category.png'),
							}}
						/> */}
						{Object.keys( categories ).map( ( cat, index ) => (
							<Category
								cat={{
									Category: categories[cat],
									ID: cat,
									icon: false,
									PrimaryImage: true,
								}}
								key={index}
							/>
						) )}
					</OwlCarousel>
				</div>
			) : (
				<div className="loading"></div>
			)}
		</>
	);
}

const mapStateToProps = ( state ) => {
	return {
		categories: state.app.selectedCategories,
		preferences: state.preferences.init,
	};
};

export default connect( mapStateToProps, {
	getCategories,
} )( Categories );
